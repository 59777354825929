<template>
  <v-container fluid>
    <v-toolbar
      v-bind="$attrs"
      v-on="$listeners"
      dense
      flat
      :prominent="prominent"
    >
      <!-- Toolbar title -->
      <v-toolbar-title v-show="hasSlot('title')" :class="titleClass">
        <slot name="title"></slot>
      </v-toolbar-title>

      <!--      <v-divider class="mx-4" inset vertical></v-divider>-->
      <!--      <span>{{ prominent }}</span>-->

      <!-- Pool right Toolbar commands buttons  -->
      <v-spacer></v-spacer>

      <!-- Toolbar commands buttons  -->
      <slot name="commands"></slot>

      <!-- Toolbar extension  -->
      <template v-slot:extension v-if="prominent">
        <slot name="extension"></slot>
      </template>
    </v-toolbar>
  </v-container>
</template>

<script>
// design
import { displayTypes, fontEmphasis, Text } from "@/design/text/Text";
import {
  Color,
  colorMD,
  variantNumber,
  variantType
} from "@/design/colors/Color";

// mixins
import { slottableMixin } from "@/mixins/shared/slottable/slottableMixin";

export default {
  name: "LayoutDataTableToolbar",
  inheritAttrs: false,
  mixins: [slottableMixin],
  props: {
    prominent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    titleClass() {
      return new Text(
        new Color(colorMD.grey, variantType.darken, variantNumber.n2),
        displayTypes.subtitle1,
        fontEmphasis.regular
      ).getClassText();
    }
  }
};
</script>
